<template>
  <v-app>
   <!-- <Header v-if="$route.path != '/'"/>
   <Headerxs v-if="$route.path != '/'"/> -->
   <Header />
   <Headerxs />
     <router-view>
     </router-view>
     <!-- <Footer v-if="$route.path != '/'"/> -->
     <Footer />
  </v-app>
</template>

<script>
import "./assets/style/fonts.css";
import "./assets/style/style.css";
import Header from './common/appHeader';
import Headerxs from './common/appHeaderxs';
import Footer from './common/footer';

export default {
  name: 'App',

  components: {
    Header,
    Footer,
    Headerxs
    
  },

  data: () => ({
    //
  }),
};
</script>
