<template>
  <div>
    <v-layout py-2 style="background-color: #2c3e50" class="hidden-lg-and-up">
        <v-flex xs2>
       <v-img height="70px" contain src="./../assets/images/logo3.png"> </v-img>
        </v-flex>
      <v-flex wrap xs2 align-self-center>
        <v-app-bar-nav-icon
          style="color: white"
          @click="nav = true"
        ></v-app-bar-nav-icon>
        <v-navigation-drawer height="450px" absolute v-model="nav">
          <v-layout class="menufont1" wrap justify-center pa-3>
            <v-flex
              xs12
              align-self-center
              text-left
              v-for="(item, i) in navitems"
              :key="i"
            >
              <v-flex pa-2 pl-6>
                <router-link style="" class="routerlink bold" :to="item.link">
                  <span class="menufont1" style="color:black">{{ item.name }}</span></router-link
                >
              </v-flex>
            </v-flex>
            <v-flex xs12 align-self-center text-left>
              <v-flex pa-2 pl-6>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="bold"> More info </span>
                  </template>
                  <v-list>
                    <v-list-item>
                      <a
                        :href="'./../../assets/Bal.pdf'"
                        style="text-decoration: none; color: black"
                      >
                        <v-list-item-title class="bold"
                          >Balance Sheet</v-list-item-title
                        >
                      </a>
                    </v-list-item>
                    <v-list-item>
                      <a
                        :href="'./../../assets/affidavit.pdf'"
                        style="text-decoration: none; color: black"
                      >
                        <v-list-item-title class="bold"
                          >Affidavit</v-list-item-title
                        >
                      </a>
                    </v-list-item>
                    <v-list-item>
                      <a
                        :href="'./../../assets/income.pdf'"
                        style="text-decoration: none; color: black"
                      >
                        <v-list-item-title class="bold"
                          >Income & Expenditure</v-list-item-title
                        >
                      </a>
                    </v-list-item>
                    <v-list-item>
                      <a
                        :href="'./../../assets/reciept.pdf'"
                        style="text-decoration: none; color: black"
                      >
                        <v-list-item-title class="bold"
                          >Reciept & Payment</v-list-item-title
                        >
                      </a>
                    </v-list-item>
                     <v-list-item>
                <a
                  :href="'./../../assets/authorised.pdf'"
                  style="text-decoration: none; color: black"
                >
                  <v-list-item-title class="bold"
                    >Authorised Representative</v-list-item-title
                  >
                </a>
              </v-list-item>
               <v-list-item>
                <a

                  :href="'./../../assets/certificate.pdf'"
                  style="text-decoration: none; color: black"
                >
                  <v-list-item-title class="bold"
                    >Certificate from the Management</v-list-item-title
                  >
                </a>
              </v-list-item>
               <!-- <v-list-item>
                <a

                  :href="'./../../assets/PERTICULARS.pdf'"
                  style="text-decoration: none; color: black"
                >
                  <v-list-item-title class="bold"
                    >Faculty</v-list-item-title
                  >
                </a>
              </v-list-item> -->
              <v-list-item>
                 <router-link
                  to="/faculty"
                  style="text-decoration: none; color: black"
                >
               
                  <v-list-item-title class="bold">Faculty</v-list-item-title>
                 </router-link>
              </v-list-item>
              <!-- <v-list-item>
                <a
                  :href="'./../../assets/scale.pdf'"
                  style="text-decoration: none; color: black"
                >
                  <v-list-item-title class="bold"
                    >Scale of pay</v-list-item-title
                  >
                </a>
              </v-list-item> -->
              <v-list-item>
                <router-link
                  to="/scholarship"
                  style="text-decoration: none; color: black"
                >
                  <v-list-item-title class="bold"
                    >Library Details</v-list-item-title
                  >
                </router-link>
              </v-list-item>
                <v-list-item>
                <router-link
                  to="/sanctionedProgram"
                  style="text-decoration: none; color: black"
                >
                  <v-list-item-title class="bold"
                    >Sanctioned Programme</v-list-item-title
                  >
                </router-link>
              </v-list-item>
                     <!-- <v-list-item>
                 <router-link to="/scholarship"   style="text-decoration: none; color: black">
                  <v-list-item-title class="bold"
                    >Scholarship</v-list-item-title
                  >
                 </router-link>
               
              </v-list-item> -->
                  </v-list>
                </v-menu>
              </v-flex>
            </v-flex>
          </v-layout>
        </v-navigation-drawer>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  data() {
    return {
      nav: false,
      navitems: [
        {
          name: "Home",
          link: "/",
        },
        {
          name: "About Us",
          link: "/aboutus",
        },
        {
          name: "Staff Details",
          link: "/staffdetails",
        },
        {
          name: "Infrastructure",
          link: "/infrastructure",
        },
        {
          name: "Gallery",
          link: "/gallery",
        },
        {
          name: "Student List",
          link: "/studentList",
        },
        {
          name: "Fees Structure",
          link: "/feestructure",
        },
      ],
    };
  },
};
</script>