<template>
  <div>
    <v-app-bar height="80px" color="rgba(0,0,0,0.7)" class="hidden-md-and-down">
      <v-toolbar-title>
        <v-img height="70px" contain src="./../assets/images/logo3.png">
        </v-img>
      </v-toolbar-title>
      <v-spacer> </v-spacer>
      <v-layout wrap class="regular hidden-xs-only" style="color: white">
        <v-flex text-left v-for="(item, i) in navitems" :key="i">
          <router-link
            :to="item.link"
            style="text-decoration: none; color: white"
          >
            <span class="px-4">
              {{ item.name }}
            </span>
          </router-link>
        </v-flex>
        <v-flex>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                More info <v-icon color="white">mdi-menu-down</v-icon>
              </span>
            </template>
            <v-list>
              <!-- <v-list-item>
                <a
                  :href="'./../../assets/Bal.pdf'"
                  style="text-decoration: none; color: black"
                >
                  <v-list-item-title class="bold"
                    >Balance Sheet</v-list-item-title
                  >
                </a>
              </v-list-item> -->
              <v-list-item>
  <v-list-item-title class="bold" @click="$router.push('/balanceSheet')" style="cursor: pointer;">
    Balance Sheet
  </v-list-item-title>
</v-list-item>

              <!-- <v-list-item>
                <a
                  :href="'./../../assets/affidavitNew.pdf'"
                  style="text-decoration: none; color: black"
                >
                  <v-list-item-title class="bold">Affidavit</v-list-item-title>
                </a>
              </v-list-item> -->

              <v-list-item>
  <v-list-item-title class="bold" @click="$router.push('/Affidavit')" style="cursor: pointer;">
    Affidavit
  </v-list-item-title>
</v-list-item>

              <!-- <v-list-item>
                <a
                  :href="'./../../assets/incomeandexp2021Crop.pdf'"
                  style="text-decoration: none; color: black"
                >
                  <v-list-item-title class="bold"
                    >Income & Expenditure</v-list-item-title
                  >
                </a>
              </v-list-item> -->

              <v-list-item>
  <v-list-item-title class="bold" @click="$router.push('/IncomeAndExpenditure')" style="cursor: pointer;">
    Income & Expenditure
  </v-list-item-title>
</v-list-item>

              <!-- <v-list-item>
                <a
                  :href="'./../../assets/Recieptandpayment.pdf'"
                  style="text-decoration: none; color: black"
                >
                  <v-list-item-title class="bold"
                    >Reciept & Payment</v-list-item-title
                  >
                </a>
              </v-list-item> -->

              <v-list-item>
  <v-list-item-title class="bold" @click="$router.push('/RecieptandPayment')" style="cursor: pointer;">
    Reciept & Payment
  </v-list-item-title>
</v-list-item>

              <!-- <v-list-item>
                <a
                  :href="'./../../assets/AuthorizationNew.pdf'"
                  style="text-decoration: none; color: black"
                >
                  <v-list-item-title class="bold"
                    >Authorised Representative</v-list-item-title
                  >
                </a>
              </v-list-item> -->

              <v-list-item>
  <v-list-item-title class="bold" @click="$router.push('/authorisedRepresentative')" style="cursor: pointer;">
    Authorised Representative
  </v-list-item-title>
</v-list-item>

<v-list-item>
  <v-list-item-title class="bold" @click="$router.push('/ManagerAuth')" style="cursor: pointer;">
    Manager Authorization
  </v-list-item-title>
</v-list-item>

              <v-list-item>
                <a
                  :href="'./../../assets/CertificateFromTheManagement.pdf'"
                  style="text-decoration: none; color: black"
                >
                  <v-list-item-title class="bold"
                    >Certificate from the Management</v-list-item-title
                  >
                </a>
              </v-list-item>
              <v-list-item>
                 <router-link
                  to="/faculty"
                  style="text-decoration: none; color: black"
                >
               
                  <v-list-item-title class="bold">Faculty</v-list-item-title>
                 </router-link>
              </v-list-item>
              <!-- <v-list-item>
                <a
                  :href="'./../../assets/scale.pdf'"
                  style="text-decoration: none; color: black"
                >
                  <v-list-item-title class="bold"
                    >Scale of pay</v-list-item-title
                  >
                </a>
              </v-list-item> -->
              <v-list-item>
                <router-link
                  to="/scholarship"
                  style="text-decoration: none; color: black"
                >
                  <v-list-item-title class="bold"
                    >Library Details</v-list-item-title
                  >
                </router-link>
              </v-list-item>
                <v-list-item>
                <router-link
                  to="/sanctionedProgram"
                  style="text-decoration: none; color: black"
                >
                  <v-list-item-title class="bold"
                    >Sanctioned Programme</v-list-item-title
                  >
                </router-link>
              </v-list-item>
              <!-- <v-list-item>
                 <router-link to="/scholarship"   style="text-decoration: none; color: black">
                  <v-list-item-title class="bold"
                    >Scholarship</v-list-item-title
                  >
                 </router-link>
               
              </v-list-item> -->
            </v-list>
          </v-menu>
        </v-flex>
      </v-layout>
    </v-app-bar>
  </div>
</template>
<script>
export default {
  data() {
    return {
      drawer: false,
      navitems: [
        {
          name: "Home",
          link: "/",
        },
        {
          name: "About Us",
          link: "/aboutus",
        },
        {
          name: "Staff Details",
          link: "/staffdetails",
        },
        {
          name: "Infrastructure",
          link: "/infrastructure",
        },
        {
          name: "Gallery",
          link: "/gallery",
        },
        {
          name: "Student List",
          link: "/studentList",
        },
        {
          name: "Fees Structure",
          link: "/feestructure",
        },
      ],
    };
  },
};
</script>




<style scoped>
.rdx {
  background-color: rgba(37, 34, 36, 0.58) !important;
}
</style>
