import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);
let router = new Router({
  mode: "history",
  routes: [
    
    // {
    //   path: "/hide",
    //   props: true,
    //   component: () => import("./home/home"),
    //   meta: {
    //     requiresAuth: false,
    //   },
    // },

    {
      path: "/",
      props: true,
      component: () => import("./home/home"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/hide",
      props: true,
      component: () => import("./welcome/welcome"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/aboutus",
      props: true,
      component: () => import("./aboutUs/aboutUs"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/faculty",
      props: true,
      component: () => import("./Faculty/faculty"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/gallery",
      props: true,
      component: () => import("./gallery/gallery"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/infrastructure",
      props: true,
      component: () => import("./infrastructure/infrastructure"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/staffdetails",
      props: true,
      component: () => import("./staffDetails/staff"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/feestructure",
      props: true,
      component: () => import("./feeStructure/feeStructure"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/balanceSheet",
      props: true,
      component: () => import("./balanceSheet/BalanceSheet.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/Affidavit",
      props: true,
      component: () => import("./Affidavit/Affidavit.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/IncomeAndExpenditure",
      props: true,
      component: () => import("./IncomeAndExpenditure/IncomeAndExpenditure.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/RecieptandPayment",
      props: true,
      component: () => import("./RecieptandPayment/RecieptandPayment.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/authorisedRepresentative",
      props: true,
      component: () => import("./AuthorisedRepresentative/authorisedRepresentative.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/ManagerAuth",
      props: true,
      component: () => import("./ManagerAuthorization/ManagerAuth.vue"),
      meta: {
        requiresAuth: false,
      },
    },

    {
      path: "/studentList",
      props: true,
      component: () => import("./studentList/list"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/studentListDetailedView",
      props: true,
      component: () => import("./studentList/listdetailedView.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "*",
      props: true,
      component: () => import("./common/404"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/scholarship",
      props: true,
      component: () => import("./scholarship/scholarship"),
      meta: {
        requiresAuth: false,
      },
      
    },
    {
      path: "/sanctionedProgram",
      props: true,
      component: () => import("./saction/saction"),
      meta: {
        requiresAuth: false,
      },
      
    },
  
  ],
  scrollBehavior() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  },
});


export default router;
